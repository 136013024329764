/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Openorigin API Documentation
 * OpenAPI spec version: 1.0.0
 */
import { apiClient } from '../base'
import { formDataMutator } from '../formDataMutator'
import type {
  AuthLogin200,
  AuthLoginBody,
  AuthRegister200,
  AuthRegisterBody,
  CommentsReactionsCreate200,
  CommentsReactionsDelete200,
  ModelCommentsCreate200,
  ModelCommentsCreateBody,
  ModelCommentsList200,
  ModelReportsCreateBody,
  ModelsCreate200,
  ModelsCreateBody,
  ModelsFavoriteAdd200,
  ModelsFavoriteRemove200,
  ModelsFavorites200,
  ModelsFavoritesParams,
  ModelsList200,
  ModelsListParams,
  ModelsMy200,
  ModelsMyParams,
  ModelsShow200,
  ModelsTotal200,
  ModelsUpdate200,
  ModelsUpdateBody,
  ModelTagsList200,
  PasswordForgot200,
  PasswordForgotBody,
  PasswordReset200,
  PasswordResetBody,
  PremiumSubscriptionSubscribeBody,
  Profile200,
  ProfileChangePassword200,
  ProfileChangePasswordBody,
  ProfileComments200,
  ProfileUpdate200,
  ProfileUpdateAccount200,
  ProfileUpdateAccountBody,
  ProfileUpdateBody,
  WidgetsCommunityStats200,
  WidgetsTeamMembers200,
  WidgetsTokenUtility200,
} from '../model'

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary Auth: Login
 */
export const authLogin = (
  authLoginBody: AuthLoginBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<AuthLogin200>(
    {
      url: `/api/auth/login`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: authLoginBody,
    },
    options,
  )
}

/**
 * @summary Auth: Register
 */
export const authRegister = (
  authRegisterBody: AuthRegisterBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<AuthRegister200>(
    {
      url: `/api/auth/register`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: authRegisterBody,
    },
    options,
  )
}

/**
 * @summary Auth: Logout
 */
export const authLogout = (options?: SecondParameter<typeof apiClient>) => {
  return apiClient<void>({ url: `/api/auth/logout`, method: 'POST' }, options)
}

/**
 * @summary Comments Reactions: Create
 */
export const commentsReactionsCreate = (
  commentId: number,
  reactionId: number,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<CommentsReactionsCreate200>(
    { url: `/api/comments/reactions/${commentId}/${reactionId}`, method: 'POST' },
    options,
  )
}

/**
 * @summary Comments Reactions: Delete
 */
export const commentsReactionsDelete = (
  commentId: number,
  reactionId: number,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<CommentsReactionsDelete200>(
    { url: `/api/comments/reactions/${commentId}/${reactionId}`, method: 'DELETE' },
    options,
  )
}

/**
 * @summary Model Comments: List
 */
export const modelCommentsList = (model: string, options?: SecondParameter<typeof apiClient>) => {
  return apiClient<ModelCommentsList200>(
    { url: `/api/models/${model}/comments`, method: 'GET' },
    options,
  )
}

/**
 * @summary Model Comments: Create
 */
export const modelCommentsCreate = (
  model: string,
  modelCommentsCreateBody: ModelCommentsCreateBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<ModelCommentsCreate200>(
    {
      url: `/api/models/${model}/comments`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: modelCommentsCreateBody,
    },
    options,
  )
}

/**
 * @summary Model Comments: Delete
 */
export const modelCommentsDelete = (
  model: string,
  comment: string,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<void>(
    { url: `/api/models/${model}/comments/${comment}`, method: 'DELETE' },
    options,
  )
}

/**
 * @summary Model Reports: Create
 */
export const modelReportsCreate = (
  model: string,
  modelReportsCreateBody: ModelReportsCreateBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<void>(
    {
      url: `/api/models/${model}/report`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: modelReportsCreateBody,
    },
    options,
  )
}

/**
 * @summary Model Tags: List
 */
export const modelTagsList = (options?: SecondParameter<typeof apiClient>) => {
  return apiClient<ModelTagsList200>({ url: `/api/model-tags`, method: 'GET' }, options)
}

/**
 * @summary Models: My
 */
export const modelsMy = (params?: ModelsMyParams, options?: SecondParameter<typeof apiClient>) => {
  return apiClient<ModelsMy200>({ url: `/api/models/my`, method: 'GET', params }, options)
}

/**
 * @summary Models: Favorite Add
 */
export const modelsFavoriteAdd = (model: string, options?: SecondParameter<typeof apiClient>) => {
  return apiClient<ModelsFavoriteAdd200>(
    { url: `/api/models/${model}/favorite`, method: 'POST' },
    options,
  )
}

/**
 * @summary Models: Favorite Remove
 */
export const modelsFavoriteRemove = (
  model: string,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<ModelsFavoriteRemove200>(
    { url: `/api/models/${model}/favorite`, method: 'DELETE' },
    options,
  )
}

/**
 * @summary Models: Favorites
 */
export const modelsFavorites = (
  params?: ModelsFavoritesParams,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<ModelsFavorites200>(
    { url: `/api/models/favorites`, method: 'GET', params },
    options,
  )
}

/**
 * @summary Models: Update
 */
export const modelsUpdate = (
  model: string,
  modelsUpdateBody: ModelsUpdateBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  const formData = formDataMutator(modelsUpdateBody)
  return apiClient<ModelsUpdate200>(
    {
      url: `/api/models/${model}`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  )
}

/**
 * @summary Models: Total
 */
export const modelsTotal = (options?: SecondParameter<typeof apiClient>) => {
  return apiClient<ModelsTotal200>({ url: `/api/models/total`, method: 'GET' }, options)
}

/**
 * @summary Models: List
 */
export const modelsList = (
  params?: ModelsListParams,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<ModelsList200>({ url: `/api/models`, method: 'GET', params }, options)
}

/**
 * @summary Models: Create
 */
export const modelsCreate = (
  modelsCreateBody: ModelsCreateBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  const formData = formDataMutator(modelsCreateBody)
  return apiClient<ModelsCreate200>(
    {
      url: `/api/models`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  )
}

/**
 * @summary Models: Show
 */
export const modelsShow = (id: string, options?: SecondParameter<typeof apiClient>) => {
  return apiClient<ModelsShow200>({ url: `/api/models/${id}`, method: 'GET' }, options)
}

/**
 * @summary Models: Delete
 */
export const modelsDelete = (id: string, options?: SecondParameter<typeof apiClient>) => {
  return apiClient<void>({ url: `/api/models/${id}`, method: 'DELETE' }, options)
}

/**
 * @summary Password: Forgot
 */
export const passwordForgot = (
  passwordForgotBody: PasswordForgotBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<PasswordForgot200>(
    {
      url: `/api/auth/forgot-password`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: passwordForgotBody,
    },
    options,
  )
}

/**
 * @summary Password: Reset
 */
export const passwordReset = (
  passwordResetBody: PasswordResetBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<PasswordReset200>(
    {
      url: `/api/auth/reset-password`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: passwordResetBody,
    },
    options,
  )
}

/**
 * @summary Premium Subscription: Subscribe
 */
export const premiumSubscriptionSubscribe = (
  premiumSubscriptionSubscribeBody: PremiumSubscriptionSubscribeBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<void>(
    {
      url: `/api/premium-subscription`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: premiumSubscriptionSubscribeBody,
    },
    options,
  )
}

/**
 * @summary Profile
 */
export const profile = (options?: SecondParameter<typeof apiClient>) => {
  return apiClient<Profile200>({ url: `/api/profile`, method: 'GET' }, options)
}

/**
 * @summary Profile: Update
 */
export const profileUpdate = (
  profileUpdateBody: ProfileUpdateBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  const formData = formDataMutator(profileUpdateBody)
  return apiClient<ProfileUpdate200>(
    {
      url: `/api/profile`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  )
}

/**
 * @summary Profile: Delete
 */
export const profileDelete = (options?: SecondParameter<typeof apiClient>) => {
  return apiClient<void>({ url: `/api/profile`, method: 'DELETE' }, options)
}

/**
 * @summary Profile: Update Account
 */
export const profileUpdateAccount = (
  profileUpdateAccountBody: ProfileUpdateAccountBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<ProfileUpdateAccount200>(
    {
      url: `/api/profile/account`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: profileUpdateAccountBody,
    },
    options,
  )
}

/**
 * @summary Profile: Change Password
 */
export const profileChangePassword = (
  profileChangePasswordBody: ProfileChangePasswordBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<ProfileChangePassword200>(
    {
      url: `/api/profile/password`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: profileChangePasswordBody,
    },
    options,
  )
}

/**
 * @summary Profile: Comments
 */
export const profileComments = (options?: SecondParameter<typeof apiClient>) => {
  return apiClient<ProfileComments200>({ url: `/api/profile/comments`, method: 'GET' }, options)
}

/**
 * @summary Widgets: Community Stats
 */
export const widgetsCommunityStats = (options?: SecondParameter<typeof apiClient>) => {
  return apiClient<WidgetsCommunityStats200>(
    { url: `/api/widgets/community-stats`, method: 'GET' },
    options,
  )
}

/**
 * @summary Widgets: Team Members
 */
export const widgetsTeamMembers = (options?: SecondParameter<typeof apiClient>) => {
  return apiClient<WidgetsTeamMembers200>(
    { url: `/api/widgets/team-members`, method: 'GET' },
    options,
  )
}

/**
 * @summary Widgets: Token Utility
 */
export const widgetsTokenUtility = (options?: SecondParameter<typeof apiClient>) => {
  return apiClient<WidgetsTokenUtility200>(
    { url: `/api/widgets/token-utilities`, method: 'GET' },
    options,
  )
}

export type AuthLoginResult = NonNullable<Awaited<ReturnType<typeof authLogin>>>
export type AuthRegisterResult = NonNullable<Awaited<ReturnType<typeof authRegister>>>
export type AuthLogoutResult = NonNullable<Awaited<ReturnType<typeof authLogout>>>
export type CommentsReactionsCreateResult = NonNullable<
  Awaited<ReturnType<typeof commentsReactionsCreate>>
>
export type CommentsReactionsDeleteResult = NonNullable<
  Awaited<ReturnType<typeof commentsReactionsDelete>>
>
export type ModelCommentsListResult = NonNullable<Awaited<ReturnType<typeof modelCommentsList>>>
export type ModelCommentsCreateResult = NonNullable<Awaited<ReturnType<typeof modelCommentsCreate>>>
export type ModelCommentsDeleteResult = NonNullable<Awaited<ReturnType<typeof modelCommentsDelete>>>
export type ModelReportsCreateResult = NonNullable<Awaited<ReturnType<typeof modelReportsCreate>>>
export type ModelTagsListResult = NonNullable<Awaited<ReturnType<typeof modelTagsList>>>
export type ModelsMyResult = NonNullable<Awaited<ReturnType<typeof modelsMy>>>
export type ModelsFavoriteAddResult = NonNullable<Awaited<ReturnType<typeof modelsFavoriteAdd>>>
export type ModelsFavoriteRemoveResult = NonNullable<
  Awaited<ReturnType<typeof modelsFavoriteRemove>>
>
export type ModelsFavoritesResult = NonNullable<Awaited<ReturnType<typeof modelsFavorites>>>
export type ModelsUpdateResult = NonNullable<Awaited<ReturnType<typeof modelsUpdate>>>
export type ModelsTotalResult = NonNullable<Awaited<ReturnType<typeof modelsTotal>>>
export type ModelsListResult = NonNullable<Awaited<ReturnType<typeof modelsList>>>
export type ModelsCreateResult = NonNullable<Awaited<ReturnType<typeof modelsCreate>>>
export type ModelsShowResult = NonNullable<Awaited<ReturnType<typeof modelsShow>>>
export type ModelsDeleteResult = NonNullable<Awaited<ReturnType<typeof modelsDelete>>>
export type PasswordForgotResult = NonNullable<Awaited<ReturnType<typeof passwordForgot>>>
export type PasswordResetResult = NonNullable<Awaited<ReturnType<typeof passwordReset>>>
export type PremiumSubscriptionSubscribeResult = NonNullable<
  Awaited<ReturnType<typeof premiumSubscriptionSubscribe>>
>
export type ProfileResult = NonNullable<Awaited<ReturnType<typeof profile>>>
export type ProfileUpdateResult = NonNullable<Awaited<ReturnType<typeof profileUpdate>>>
export type ProfileDeleteResult = NonNullable<Awaited<ReturnType<typeof profileDelete>>>
export type ProfileUpdateAccountResult = NonNullable<
  Awaited<ReturnType<typeof profileUpdateAccount>>
>
export type ProfileChangePasswordResult = NonNullable<
  Awaited<ReturnType<typeof profileChangePassword>>
>
export type ProfileCommentsResult = NonNullable<Awaited<ReturnType<typeof profileComments>>>
export type WidgetsCommunityStatsResult = NonNullable<
  Awaited<ReturnType<typeof widgetsCommunityStats>>
>
export type WidgetsTeamMembersResult = NonNullable<Awaited<ReturnType<typeof widgetsTeamMembers>>>
export type WidgetsTokenUtilityResult = NonNullable<Awaited<ReturnType<typeof widgetsTokenUtility>>>
